import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Check } from "@mui/icons-material";
import { BUSINESS_URL } from "../../config/config";
import logo from "../../../src/assets/images/logo.png";
import { notifySucess, notifyError } from "../../components/Toast/Toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/Loader/Loader";

const stripePromise = loadStripe(
  "pk_test_51M2uPpA4eIlTVQ4deZnhDovjWesNd3MDm7nvqJaxlLxA3mZDPHdRMxHtAxO5jqV7l2J7D0uI6aNqEuTgUrGnH1OW00FONtQh9d"
);

const inputStyle = {
  iconColor: "#c4f0ff",
  color: "black",
  fontWeight: "800",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",
  border: "3px solid black",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#fce883",
  },
  "::placeholder": {
    color: "#87BBFD",
  },
  padding: "200px",
};

const PaymentForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (stripe, elements) => () => {
    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then((payload) => {
      console.log("Generated Card Token --->", payload?.token);
      axios
        .post(BUSINESS_URL + `/create-subscription`, {
          token: payload?.token?.id,
          email: searchParams.get("email"),
          planName: searchParams.get("planName"),
        })
        .then(async (res) => {
          if (res.data?.status === 200) {
            // setIsLoading(false);
            notifySucess("Subscription purchased successfully");
            await navigate(`/login?email=${searchParams.get("email")}`);
          }
        })
        .catch((err) => notifyError("Subscription purchase failed"));
    });
  };
  return (
    <>
      <section className="subscription-bg">
        {isLoading ? (
          <div
            className="spinner-container"
            style={{
              margin: "0",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <div className="container py-3">
            <header className="text-center" style={{ marginBottom: "4rem" }}>
              <div className="row">
                <div className="col-lg-7 mx-auto">
                  <div className="logo pb-2">
                    <a href="sign_in.html">
                      <img src={logo} alt="logo" className="mx-auto d-block" />
                    </a>
                  </div>
                  <h2 className="fw-bolder text-center head-black mb-2">
                    Payment Form
                  </h2>
                  <p className="lead text-center head-muted pb-2">
                    Please enter your card details
                  </p>
                </div>
              </div>
            </header>
            <div className="row justify-content-center">
              {/* Pricing Table*/}
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="card border-0">
                  <div
                    className="card-body"
                    style={{ height: "10rem", padding: "2rem" }}
                  >
                    <div className="payment-form">
                      <CardElement options={{ style: { base: inputStyle } }} />
                      <button
                        className="btn btn-linear w-100 mt-4"
                        onClick={handleSubmit(stripe, elements)}
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export const StripePaymentForm = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

/**
 * ! Backup
 */

// import {
//   PaymentElement,
//   useStripe,
//   useElements,
//   Elements,
//   CardElement,
// } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { useEffect } from "react";

// const Payment = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const CARD_ELEMENT_OPTIONS = {
//     style: {
//       base: {
//         color: "#333",
//         fontFamily: "'Maven Pro', sans-serif",
//         cssSrc:
//           "https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap",
//         fontWeight: "400",
//         fontSize: "13px",
//         "::placeholder": {
//           color: "#a5afb5",
//         },
//       },
//       invalid: {
//         color: "red",
//         iconColor: "red",
//       },
//     },
//   };

//   useEffect(() => {
//     if (elements) {
//       const cardNumberElement =
//         elements.getElement("cardNumber") ||
//         elements?.create("cardNumber", {
//           style: CARD_ELEMENT_OPTIONS.style,
//           placeholder: "Card Number",
//           showIcon: true,
//         });

//       const cardExpiryElement =
//         elements.getElement("cardExpiry") ||
//         elements?.create("cardExpiry", {
//           style: CARD_ELEMENT_OPTIONS.style,
//           placeholder: "MM/YY",
//         });

//       const cardCvvElement =
//         elements.getElement("cardCvc") ||
//         elements?.create("cardCvc", {
//           style: CARD_ELEMENT_OPTIONS.style,
//           placeholder: "CVC",
//         });

//       cardNumberElement?.mount("#card-number-element");
//       cardExpiryElement?.mount("#card-expiry-element");
//       cardCvvElement?.mount("#card-cvc-element");
//     }
//   }, [elements]);

//   const handleSubmit = (stripe, elements) => async () => {
//     const cardElement = elements.getElement(CardElement);

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: "card",
//       card: cardElement,
//     });

//     if (error) {
//       console.log("[error]", error);
//     } else {
//       console.log("[PaymentMethod]", paymentMethod);
//       // ... SEND to your API server to process payment intent
//     }
//   };

//   return (
//     <div className="Payment  mb-5 pb-5 mt-pay-4">
//       <section className="payment-info w-100">
//         <div className="col-12 cd-px-md-4">
//           <h1 className="title px-0">Payment Info</h1>
//         </div>

//         <div className="col-12 px-4 cd-px-md-4">
//           <input type="hidden" name="token" />

//           <div
//             className="group mb-0 cardDetails-sec"
//             style={{ display: "flex", flexDirection: "column" }}
//           >
//             <label>
//               <span className="card-label">CARD HOLDER NAME</span>
//               <input
//                 placeholder="Card Holder Name"
//                 id="card-holder-name"
//                 style={{
//                   paddingLeft: 0,
//                   borderBottom: "1px solid  rgb(221, 221, 221)",
//                   width: "100%",
//                 }}
//               />
//             </label>
//             <label>
//               <span className="card-label">CARD NUMBER</span>
//               <div id="card-number-element" className="field"></div>
//             </label>
//             <label>
//               <span>EXPIRATION DATE</span>
//               <div id="card-expiry-element" className="field"></div>
//             </label>
//             <label className="mb-8 mb-new-8">
//               <span>CVC</span>
//               <div id="card-cvc-element" className="field"></div>
//             </label>
//           </div>
//           <button onClick={handleSubmit(stripe, elements)}>Buy</button>
//         </div>
//       </section>
//     </div>
//   );
// };

// const CheckoutForm = () => {
//   const stripePromise = loadStripe(
//     "pk_test_51M2uPpA4eIlTVQ4deZnhDovjWesNd3MDm7nvqJaxlLxA3mZDPHdRMxHtAxO5jqV7l2J7D0uI6aNqEuTgUrGnH1OW00FONtQh9d",
//     {}
//   );
//   return (
//     <Elements stripe={stripePromise}>
//       <Payment />
//     </Elements>
//   );
// };

// export default CheckoutForm;
