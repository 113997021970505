// ! Local
// const BUSINESS_URL = "http://localhost:6001/v1/business";

// ! Development
// const BUSINESS_URL = "https://lyhammer-api.alcax.com/v1/business";

// ! Production
const BUSINESS_URL = "https://api.lyhammer.com/v1/business";

export { BUSINESS_URL };
