import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function notifyError(message) {
  toast.error(message, {
    theme: "colored",
    autoClose: 1500,
  });
}

function notifySucess(message) {
  toast.success(message, {
    theme: "colored",
    autoClose: 1500,
  });
}

export { notifySucess, notifyError };
